import * as React from 'react';
import SEO from '../components/shared/SEO';
import Container from '../components/shared/Container';

const Terms = () => (
  <Container>
    <SEO title="Kuma Learn - Terms of Service - Using This Site" description="By accessing the site, you're agreeing to be bound by these terms, all applicable laws and agree you're responsible for compliance with applicable local laws." />
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Tugboat Coding Limited Terms of Service</span>
          </h1>
        </div>
        <div className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">

          <h3>1. Terms</h3>

          <p>By accessing the website at <a className="underline" href="https://www.kumalearn.com" target="_blank" rel="noreferrer nofollow">Kuma Learn</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>

          <h3>2. Use Licence</h3>

          <ul>

            <li>Permission is granted to temporarily download one copy of the materials (information or software) on Tugboat Coding Limited's website for personal, non-commercial transitory viewing only. This is the grant of a licence, not a transfer of title, and under this licence you may not:</li>
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
            <li>attempt to decompile or reverse engineer any software contained on Tugboat Coding Limited's website;</li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
            <li>This licence shall automatically terminate if you violate any of these restrictions and may be terminated by Tugboat Coding Limited at any time. Upon terminating your viewing of these materials or upon the termination of this licence, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
          </ul>

          <h3>3. Disclaimer</h3>

          <ul>
            <li>The materials on Tugboat Coding Limited's website are provided on an 'as is' basis. Tugboat Coding Limited makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
            <li>Further, Tugboat Coding Limited does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
          </ul>

          <h3>4. Limitations</h3>

          <p>In no event shall Tugboat Coding Limited or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Tugboat Coding Limited's website, even if Tugboat Coding Limited or a Tugboat Coding Limited authorised representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

          <h3>5. Accuracy of materials</h3>

          <p>The materials appearing on Tugboat Coding Limited's website could include technical, typographical, or photographic errors. Tugboat Coding Limited does not warrant that any of the materials on its website are accurate, complete or current. Tugboat Coding Limited may make changes to the materials contained on its website at any time without notice. However Tugboat Coding Limited does not make any commitment to update the materials.</p>

          <h3>6. Links</h3>

          <p>Tugboat Coding Limited has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Tugboat Coding Limited of the site. Use of any such linked website is at the user's own risk.</p>

          <h3>7. Modifications</h3>

          <p>Tugboat Coding Limited may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>

          <h3>8. Governing Law</h3>

          <p>These terms and conditions are governed by and construed in accordance with the laws of London and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
        </div>
      </div>
    </div>
  </Container>
);

export default Terms;
